<template>
  <!-- <b-card title="Create Awesome 🙌">
    <b-card-text>This is your second page.</b-card-text>
    <b-card-text
      >Chocolate sesame snaps pie carrot cake pastry pie lollipop muffin. Carrot
      cake dragée chupa chups jujubes. Macaroon liquorice cookie wafer tart
      marzipan bonbon. Gingerbread jelly-o dragée chocolate.</b-card-text
    >
  </b-card> -->
  <section>
    <b-row>
      <b-col cols="12">
        <invoice-list />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BCard, BCardText, BRow, BCol } from "bootstrap-vue";
import InvoiceList from "@/views/InvoiceList.vue";

export default {
  components: {
    BCard,
    BCardText,

    BRow,
    BCol,

    InvoiceList,
  },
  data() {
    return {
      data: {},
    };
  },
  created() {
    // data
    /* this.$http.get("/analytics/data").then((response) => {
      this.data = response.data;
    }); */
  },
};
</script>

<style></style>
